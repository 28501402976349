/*
 * cmn.js
 */

import $ from "jquery";  //node_modlesの中にあるものを使用探しに行ってくれる
import "jquery.cookie";
import "slick-carousel";
import "../scss/style.scss";

$(function(){
/* ==========================================================
!stack
========================================================== */
/* 関数の読み込み
------------------------------------ */
	pageTop();
	pageTopFixed();
	pageScroll();
	Accordion();
	tabMenu();
	scrollAnimation();
	//imageSwitch();
	headerScrl();
	gnavmenu();
	slider();
});

$(window).on("load", function () {
/* ==========================================================
!stack window load
========================================================== */
/* window load後読み込む関数
------------------------------------ */
	opening();
});

/* ==========================================================
!pageTop
========================================================== */
const pageTop = function () {
	$(".pagetop").click(function () {
		$("html, body").animate({ scrollTop: 0 }, 1000, "easeInOutCubic");
		return false;
	});
};
/* ==========================================================
!fixed pagetop scroll
========================================================== */
const pageTopFixed = function () {
	const pageTopBox = $(".js-pagetop_fixed");
	const pageTopLink = $(".js-pagetop_fixed");

	pageTopLink.on("click", function () {
		$("html, body").animate({ scrollTop: 0 }, 1000);
		return false;
	});

	$(window).on("scroll", function () {
		if ($(this).scrollTop() > 660) {
			pageTopBox.fadeIn();
		} else {
			pageTopBox.fadeOut();
		}

		const scrollHeight = $(document).height();
		const scrollPosition = $(window).height() + $(window).scrollTop();
		const footHeight = $("#footer").innerHeight();
		if (scrollHeight - scrollPosition <= footHeight) {
			pageTopLink.css({ position: "absolute", bottom: "0px" });
		} else {
			pageTopLink.css({ position: "fixed", bottom: "50px" });
		}
	});
};
/* ==========================================================
!pageScroll
========================================================== */
const pageScroll = function () {
	$('a[href*="#"]:not(".inline"), .scroll').click(function () {
		$("html, body").animate(
			{
				scrollTop: $($.attr(this, "href")).offset().top - 120, // - 70 等といれるとそのピクセル数分増えた状態でスクロールが止まる(ヘッダー固定の時に利用)
			},
			500
		);
		return false;
	});
};
/* ==========================================================
!Accordion
========================================================== */
const Accordion = function () {
	const accordion01 = $(".js-accordion dt");

	// クリックしたとき
	accordion01.on("click", function () {
		$(this).toggleClass("u-active").next().slideToggle();
	});

	// エンターキーを押したとき
	accordion01.on("keyup", function (e) {
		if (e.keyCode == 13) {
			$(this).toggleClass("u-active").next().slideToggle();
		}
	});

	/* window.matchMedia
	------------------------------------ */
	if (window.matchMedia("(max-width:768px)").matches) {
		// (1)windowの読み込みが完了したときに、767px以下の場合に行いたいもの


	} else {
		// (2)windowの読み込みが完了したときに、768px以上の場合に行いたいもの


	}

	function matchFunction() {
		// (3)windowサイズを変更して、767px以下になったら発火するイベント


		if (window.matchMedia("(min-width:769px)").matches) {
			// (4)windowサイズを変更して、768px以上になったら発火するイベント


		}
	}
	window.matchMedia("(max-width:768px)").addListener(matchFunction);

};



/* ==========================================================
!tab
========================================================== */
const tabMenu = function () {
	const tabLink = $("#tab_link li a");
	tabLink.on("click", function () {
		$("#tab_contents > div").hide();
		$($(this).attr("href")).fadeToggle();
	});
};
/* ==========================================================
!Scroll Animation
========================================================== */
const scrollAnimation = function () {
	const AnimTrigger = $(".js-effect"); //アニメーショントリガー
	const windowHeight = $(window).height(); //ウインドウの高さ

	// ロードした際に可視範囲に入っていたら実行される
	$(window).on("load", function () {
		AnimTrigger.each(function () {
			const imgPos = $(this).offset().top; //「AnimTrigger」の位置

			if (imgPos < windowHeight) {
				$(this).addClass("aniOn");
			}
		});
	});

	// スクロールした際に「AnimTrigger」ごとに、以下に記述する内容が実行される
	$(window).scroll(function () {
		const scroll = $(window).scrollTop(); //スクロールした量

		AnimTrigger.each(function () {
			const imgPos = $(this).offset().top; //「AnimTrigger」の位置

			//アニメーションする条件＝「画面の下から1/5まできた時点でアニメーション」
			if (scroll > imgPos - windowHeight + windowHeight / 2) {
				//スクロール量　が次の条件より多いとき　…　条件：トリガーの場所　引く　ウィンドウの高さ　足す　ウィンドウの高さ　割る　5（- windowHeight + windowHeight / 5 なので6/5でなく4/5）
				$(this).addClass("aniOn");
			}
		});
	});
};

/* ==========================================================
!ImgChange
========================================================== */
//const imageSwitch = function () {
//	const $elem = $(".js-image-switch");
//	const sp = "_on.";
//	const pc = "_off.";
//
//	/* window.matchMedia
//	------------------------------------ */
//	const mediaQueryList = window.matchMedia("(min-width: 769px)");
//	const listener = (event) => {
//		// リサイズ時に行う処理
//		if (event.matches) {
//			// 769px以上
//			$elem.each(function() {
//				const $this = $(this);
//				$this.attr("src", $this.attr("src").replace(sp, pc));
//			});
//		} else {
//			// 768px以下
//			$elem.each(function() {
//				const $this = $(this);
//				$this.attr("src", $this.attr("src").replace(pc, sp));
//			});
//		}
//	};
//
//	// リスナー登録
//	mediaQueryList.addEventListener("change", listener);
//
//	// 初期化処理
//	listener(mediaQueryList);
//
//};
/* ==========================================================
!headerScrl
========================================================== */
const headerScrl = function () {
	const header = $(".js-headerscrl");
	const $elem = $(".js-scrl-switch");
	const sp = "_on.";
	const pc = "_off.";
	/* window.matchMedia
	------------------------------------ */
	const mediaQueryList = window.matchMedia("(min-width: 769px)");
	const listener = (event) => {
		// リサイズ時に行う処理
		if(header.hasClass("lower")) {
			$elem.each(function() {
				const $this = $(this);
				$this.attr("src", $this.attr("src").replace(pc, sp));
			});
		} else if (event.matches) {
			// 769px以上
			$(window).on("scroll", function() {
				if ($(this).scrollTop() > 660) {
					if (event.matches) {
						header.addClass("is-scrl");
						$elem.each(function() {
							const $this = $(this);
							$this.attr("src", $this.attr("src").replace(pc, sp));
						});
					}
				} else {
					if (event.matches) {
						header.removeClass("is-scrl");
						$elem.each(function() {
							const $this = $(this);
							$this.attr("src", $this.attr("src").replace(sp, pc));
						});
					}
				}
			});
		} else {
			// 768px以下
		}
	};

	// リスナー登録
	mediaQueryList.addEventListener("change", listener);

	// 初期化処理
	listener(mediaQueryList);

}

/* ==========================================================
!gnavmenu
========================================================== */
	const gnavmenu = function () {
		const trigger = $(".js-gnavacco");
		const spTrigger = $(".js-sp-nav");
		const closeTrigger = $(".js-nav-close");
		/* window.matchMedia
	------------------------------------ */
		// (1)windowの読み込みが完了したときに、767px以下の場合に行いたいもの
		spTrigger.on("click", function(){
			if (window.matchMedia("(max-width:768px)").matches) {
				$(".l-gnavi__submenu").hide();
				if($(this).hasClass("is-current")) {
					$(".l-gnavi__submenu.is-local").stop().slideToggle();
				} else {
					$(this).next(".l-gnavi__submenu").stop().slideToggle();
				}
				return false;
			}
		});

		closeTrigger.on("click", function(){
			if (window.matchMedia("(max-width:768px)").matches) {
				$(this).parent(".l-gnavi__submenu").stop().slideToggle();
				return false;
			}
		});

		trigger.hover (
			function() {
				if (window.matchMedia("(max-width:768px)").matches) {
				} else {
					if(!$(this).hasClass("is-current")) {
						$(this).children(".l-gnavi__submenu").stop().fadeIn();
					}
				}
			},
			function() {
				if (window.matchMedia("(max-width:768px)").matches) {
				} else {
					if(!$(this).hasClass("is-current")) {
						$(this).children(".l-gnavi__submenu").stop().fadeOut();
					}
				}
			}
		);

	};

/* ==========================================================
!gnavmenu
========================================================== */
const slider = function () {

	$(".js-slider")
	.on("init", function () {
		$('.slick-slide[data-slick-index="0"]').addClass("slick-animation");
	})
	.slick({
		autoplay: true,
		infinite: true,
		fade: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		speed: 2000,
		autoplaySpeed: 8000,
		pauseOnFocus: false,
		pauseOnHover: false
	})
	.on({
		// スライドが移動する前に発生するイベント
		beforeChange: function (event, slick, currentSlide, nextSlide) {
			//表示されているスライドに.slick-animationのクラスをつける
			$(".slick-slide", this).eq(nextSlide).addClass("slick-animation");

			//あとで、.slick-animationのクラスを消すための.stop-animationクラスを付ける
			$(".slick-slide", this).eq(currentSlide).addClass("stop-animation");
		},
		// スライドが移動した後に発生するイベント
		afterChange: function () {
			//見えてないスライドにはアニメーションのクラスを外す
			$(".stop-animation", this).removeClass("stop-animation slick-animation");
		},
	});
};

/* ==========================================================
!opening
========================================================== */
const opening = function () {
	$.cookie("name", "myname", { expires: 1 });
	const opening_flg = $.cookie("opening_flg");
	if (opening_flg != 1) {
		$(".p-opening__logo").delay(1000).fadeOut("slow", function(){
			$(".p-openingbg").addClass("move");
		});
		$(".p-openingbg").on('animationend', function() {
			$(".p-opening").delay(500).fadeOut("slow");
			setTimeout(function(){
				$(".p-opening").remove();
				$(".p-openingbg").remove();
			}, 1000)
		});
		$.cookie("opening_flg", 1, { expires: 1 });
	} else {
		$(".p-opening").remove();
		$(".p-openingbg").remove();
	}
};

